import { Tokens } from '@vli/locomotive-ds';
import { add, format, getMonth, isAfter } from 'date-fns';
import isToday from 'date-fns/isToday';
import { ACTIONS, canExecuteAction, ROLES } from 'lib/contexts/permissions';
import { GET } from 'lib/core/localStorage';
import * as utility from 'lib/core/utility';

export const DataFilter = (customers, accountManagers) => {
  const customerData = customers?.map((customer) => ({
    label: customer.name,
    value: {
      id: customer.id,
      others: '',
    },
  }));
  const accountManagerData = accountManagers?.map((accountManager) => ({
    label: accountManager.name,
    value: {
      id: accountManager.id,
      others: '',
    },
  }));
  const dataFilter = [
    {
      label: 'Vigência inicial',
      name: 'startPeriod',
      type: 'date',
    },
    {
      label: 'Vigência final',
      name: 'endPeriod',
      type: 'date',
    },
    {
      data: accountManagerData,
      label: 'Gerente',
      type: 'multiselect',
      name: 'accountManager',
    },
    {
      data: customerData,
      label: 'Cliente',
      type: 'multiselect',
      name: 'customer',
    },
  ];
  return dataFilter;
};
export const getFilterParams = (data, viewScenario) => {
  const startPeriodData = data.startPeriod
    ? utility.formatDate(data.startPeriod, 'yyyy-mm-dd')
    : null;
  const endPeriodData = data.endPeriod
    ? utility.formatDate(data.endPeriod, 'yyyy-mm-dd')
    : null;
  const accountManagerIds = data.accountManager
    ? data.accountManager.map((account) => account.value.id)
    : null;
  const customerIds = data.customer
    ? data.customer.map((customer) => customer.value.id)
    : null;
  const filterFields = {
    filterFields: {
      startPeriod: startPeriodData,
      endPeriod: endPeriodData,
      AccountManagerId: accountManagerIds,
      CustomerId: customerIds,
      ViewScenario: viewScenario,
      PageIndex: data.pageIndex,
      PageSize: data.pageSize,
    },
  };
  return `?${utility.autoListingParamsGet(filterFields)}`;
};

export const getFilterDetailsParams = ({ id, viewScenario }) => {
  const filterFields = {
    filterFields: {
      ContractId: id,
      ViewScenario: viewScenario,
    },
  };
  return `?${utility.autoListingParamsGet(filterFields)}`;
};

export const getDateApplication = (railwayItems) => {
  const data = railwayItems[0].calculatedValue.dateApplication;
  if (data) {
    const newDate = add(new Date(data), {
      months: 1,
    });
    return utility.formatDate(newDate, 'dd/mm/yyyy');
  }
  return null;
};

const getDateWithoutTime = (date) => {
  date.setHours(0, 0, 0, 0);

  return date;
};

export const getContractStatus = ({ details }) => {
  const now = getDateWithoutTime(new Date());
  const endPeriod = getDateWithoutTime(new Date(details.endPeriod));

  if (isAfter(now, endPeriod)) {
    return true;
  }

  return false;
};

export const getOptionsFilter = (details) => {
  const isClosed = getContractStatus({ details });
  if (isClosed) {
    return [
      {
        label: 'Encerrado',
        value: 'ClosedRailwayItems',
      },
      {
        label: 'Inativos',
        value: 'Inactive',
      },
    ];
  }
  return [
    {
      label: 'Preço atual',
      value: 'CurrentPrice',
    },
    {
      label: 'Próxima aplicação',
      value: 'NextPrice',
    },
    {
      label: 'Inativos',
      value: 'Inactive',
    },
    {
      label: 'Encerrado',
      value: 'ClosedRailwayItems',
    },
  ];
};

export const getDataRailwayItems = (list) => {
  const dataTable = list.map((obj) => {
    const newObj = {
      ...obj,
      headlight: obj.headlight,
      railwayItemCode: obj.railwayItemCode,
      originDestination: `${obj.origin} -> ${obj.destination}`,
      productUnicomMerchandiseTacito: obj.productUnicomMerchandiseTacito,
      dateApplication: obj.calculatedValue?.dateApplication,
      valueContracted: obj.valueContracted,
      valueRetrieved: obj.validityRailwayItem?.valueRetrieved,
      calculatedValue: obj.calculatedValue?.value,
      deltaPrice: obj.tariffDelta,
      history: obj.history,
      statusText: obj.history?.statusText,
      statusTooltip: obj.history?.statusTooltip,
    };
    return newObj;
  });
  return dataTable;
};

export const getDataCurved = (list) => {
  const tableData = list.map((listItem) => {
    return {
      ...listItem,
      originDestination: `${listItem.originCode.trim()} -> ${listItem.destinationCode.trim()}`,
      statusHistory: listItem.history?.statusText,
    };
  });

  return tableData;
};

export const getCountItems = (list) => {
  let count = 0;
  list.forEach((item) => {
    count += item.childrens.length;
  });
  return count;
};

export const getRailwayItems = (list) => {
  const railwayItems = [];
  list.forEach((item) => {
    railwayItems.push(item.childrens);
  });
  return railwayItems.flat();
};

export const FareType = {
  FIXED_TARIFF: 0,
  CURVED_TARIFF: 1,
};

export const getContractColor = ({ details }) => {
  const now = getDateWithoutTime(new Date());
  const endPeriod = getDateWithoutTime(new Date(details.endPeriod));

  if (isAfter(now, endPeriod)) {
    return Tokens.colors.gray100;
  }

  return Tokens.colors.grayWhite;
};

export const getItemStatusColor = ({ status }) => {
  if (status === 'Inativo') {
    return Tokens.colors.gray100;
  }
  return Tokens.colors.grayWhite;
};

export const getGraphValues = (item) =>
  item.curvedFares.map((newData) => ({
    Contratado: newData.contractedValue,
    Calculado: newData.calculatedValue,
    name: `${utility.getMonth[newData.monthDateProjection]}/${format(
      new Date(item.applicationDate),
      'yy',
    )}`,
  }));

export const getTableValues = (item) => {
  const calculated = [];
  const predicted = [];

  const retroactive = item.curvedFaresRetroactive?.map((curvedFare) => {
    return {
      calculated: curvedFare.calculatedValue,
      name: `${utility.getMonth[curvedFare.monthDateProjection]}/${String(
        curvedFare.yearDateProjection,
      ).slice(-2)}`,
    };
  });

  const currentMonth = getMonth(new Date(item.applicationDate)) + 1;

  item.curvedFares.forEach((curvedFare) => {
    const isPredicted = curvedFare.monthDateProjection > currentMonth;
    const curvedFareName = `${
      utility.getMonth[curvedFare.monthDateProjection]
    }/${format(new Date(item.applicationDate), 'yy')}`;

    if (isPredicted) {
      predicted.push({
        contracted: curvedFare.contractedValue,
        calculated: curvedFare.calculatedValue,
        name: curvedFareName,
      });
    } else {
      calculated.push({
        contracted: curvedFare.contractedValue,
        calculated: curvedFare.calculatedValue,
        name: curvedFareName,
      });
    }
  });

  return { calculated, predicted, retroactive };
};

export const modeEditRow = (contracts, row, editMode) => {
  return contracts?.map((contract) => {
    if (row.id === contract.id) {
      return {
        ...contract,
        editMode: true,
      };
    }
    return {
      ...contract,
      editMode: editMode ? false : null,
    };
  });
};

export const resetEditRow = (contracts) => {
  return contracts.map((contract) => {
    return {
      ...contract,
      editMode: false,
    };
  });
};

export const canEditContract = () => {
  const { roles } = GET('roles');
  return canExecuteAction(ROLES.CONTRACT, ACTIONS.UPDATE, roles);
};

export const getOptionsActiveContracts = (list) => {
  const options = list.map((contracts) => ({
    label: contracts.code,
    value: contracts.code,
  }));
  return options;
};

export const getDieselReportParams = (startDate, endDate, contractId) => {
  const startPeriodData = startDate
    ? utility.formatDate(startDate, 'yyyy-mm-dd')
    : null;
  const endPeriodData = endDate
    ? utility.formatDate(endDate, 'yyyy-mm-dd')
    : null;
  const filterFields = {
    filterFields: {
      StartDate: startPeriodData,
      EndDate: endPeriodData,
      ContractId: contractId,
    },
  };
  return `?${utility.autoListingParamsGet(filterFields)}`;
};

export const getValidity = (contract) => {
  if (contract.dieselReadjustmentStartingPoint === 0) {
    return `${contract.applicationDate}${utility.formatDate(
      contract.startPeriod,
      '/mm',
    )}`;
  }
  const newDate = add(new Date(contract.startPeriod), {
    months: 1,
  });
  return `${contract.applicationDate}${utility.formatDate(newDate, '/mm')}`;
};

export const negociationFormData = (data) => {
  const formData = new FormData();
  if (data.file) {
    formData.append('file', data.file);
  }

  data.historys.forEach((d, index) => {
    formData.append(`historys[${index}].id`, d.id);
    formData.append(
      `historys[${index}].newCalculatedValue`,
      d.newCalculatedValue.replaceAll('.', ''),
    );
  });

  formData.append('justification', data.justification);

  formData.append('negociationType', data.negociationType);

  return formData;
};

export const hasPermissionToDeleteContract = () => {
  const { roles } = GET('roles');
  return canExecuteAction(ROLES.CONTRACT, ACTIONS.DELETE, roles);
};

export const hasPermissionToDownloadContract = () => {
  const { roles } = GET('roles');
  return canExecuteAction(ROLES.CONTRACT, ACTIONS.DOWNLOAD, roles);
};

export const canCloseContract = ({ details }) => {
  const isClosed = getContractStatus({ details });
  const isCloseDay = isToday(new Date(details.endPeriod));

  const { roles } = GET('roles');

  return (
    canExecuteAction(ROLES.CONTRACT, ACTIONS.CLOSE, roles) &&
    !isClosed &&
    !isCloseDay
  );
};

export const getReportNameBasedOnType = (
  type,
  { client, code, startDate, endDate },
) => {
  switch (type) {
    case 'inflation':
      return `Relatório Inflação ${client} - ${code}`;

    default:
      return `Relatório Diesel ${client} - ${code} (${utility.formatDate(
        startDate,
        'dd_mm_yyyy',
      )} - ${utility.formatDate(endDate, 'dd_mm_yyyy')})`;
  }
};

export const setPriceActionItem = (state, payload) => {
  if (payload.item === 'all') {
    if (state.contractDetails.details.fareType === FareType.FIXED_TARIFF) {
      return state.contractDetails.details.railwayItems;
    }
    return getRailwayItems(state.contractDetails.details.railwayItems);
  }
  return [payload.item];
};

export const getRailwayItemsLocal = (railwayItems = []) => {
  const filteredItems = railwayItems.filter(
    (railwayItem) => !railwayItem.history?.canRedirectToHistoryPage,
  );

  return [...new Set(filteredItems.map((item) => item.local))];
};

export const getGroupedRailwayItemsId = (railwayItem) => {
  return railwayItem?.childrens?.map((child) => child.history.id);
};

export const ApproveTypes = {
  Undefined: 0,
  Individual: 1,
  Mass: 2,
  Grouped: 3,
};
