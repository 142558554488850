import {
  Button,
  Card,
  Flex,
  Heading,
  Text,
  Tokens,
} from '@vli/locomotive-ds/dist';
import { format } from 'date-fns';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import { SpacingTop } from 'components/core/Spacing';

import {
  getContractColor,
  getDateApplication,
  getValidity,
  getContractStatus as isContractClose,
} from 'lib/contexts/contracts';
import * as utility from 'lib/core/utility';

import ExtendModal from '../Modals/Extend';
import ServiceModal from '../Modals/Service';

import * as S from './CardDetail.styles';

const CardDetail = () => {
  const { contractDetails } = useSelector(
    ({ contractDetail }) => contractDetail,
  );

  const [isExtendModalOpen, setIsExtendModalOpen] = useState(false);
  const [isServiceModalOpen, setIsServiceModalOpen] = useState(false);

  const handleOpenExtendModal = () => {
    setIsExtendModalOpen(true);
  };

  const handleCloseExtendModal = () => {
    setIsExtendModalOpen(false);
  };

  const handleOpenServiceModal = () => {
    setIsServiceModalOpen(true);
  };

  const handleCloseServiceModal = () => {
    setIsServiceModalOpen(false);
  };

  const formatDate = (start, end) => {
    if (start && end) {
      const startDate = format(new Date(start), 'dd/MM/yyyy');
      const endDate = format(new Date(end), 'dd/MM/yyyy');
      return `${startDate} à ${endDate}`;
    }

    return '';
  };

  const shouldShowJustification =
    isContractClose({ details: contractDetails.details }) &&
    !!contractDetails.details.closingJustification;

  return (
    <Card
      data-testid="card-detail"
      style={{ backgroundColor: getContractColor(contractDetails) }}
    >
      <S.CardHeader>
        <Heading tag="h5" color={Tokens.colors.gray300} variant={['normal']}>
          {utility.capitalizeString(contractDetails.details.client)}
        </Heading>

        <Flex spaceChildren="16px">
          <Button
            variant="secondary"
            name="Inserir serviços"
            action={handleOpenServiceModal}
          />

          {contractDetails.details.fareType === 0 && (
            <Button
              variant="secondary"
              name="Prorrogar contrato"
              action={handleOpenExtendModal}
            />
          )}
        </Flex>
      </S.CardHeader>
      <SpacingTop sm>
        <Flex>
          <Flex flexDirection="column" margin="5px" minWidth="200px">
            <Text size="small" color={Tokens.colors.gray400}>
              Gerente de conta
            </Text>
            <Text
              size="medium"
              style={{
                fontWeight: '400',
              }}
            >
              {contractDetails.details.accountManager}
            </Text>
          </Flex>

          <Flex flexDirection="column" margin="5px" minWidth="200px">
            <Text size="small" color={Tokens.colors.gray400}>
              Vigência
            </Text>
            <Text
              size="medium"
              style={{
                fontWeight: '400',
              }}
            >
              {contractDetails.details.startPeriod &&
                formatDate(
                  contractDetails.details.startPeriod,
                  contractDetails.details.endPeriod,
                )}
            </Text>
          </Flex>
          <Flex flexDirection="column" margin="5px" minWidth="200px">
            <Text size="small" color={Tokens.colors.gray400}>
              Primeiro reajuste
            </Text>
            <Text
              size="medium"
              style={{
                fontWeight: '400',
              }}
            >
              {getValidity(contractDetails.details)}
            </Text>
          </Flex>

          <Flex flexDirection="column" margin="5px" minWidth="200px">
            <Text size="small" color={Tokens.colors.gray400}>
              Data da assinatura
            </Text>
            <Text
              size="medium"
              style={{
                fontWeight: '400',
              }}
            >
              {contractDetails?.details?.signingDate &&
                format(
                  new Date(contractDetails.details.signingDate),
                  'dd/MM/yyyy',
                )}
            </Text>
          </Flex>

          <Flex flexDirection="column" margin="5px" minWidth="200px">
            <Text size="small" color={Tokens.colors.gray400}>
              Data de inclusão
            </Text>
            <Text
              size="medium"
              style={{
                fontWeight: '400',
              }}
            >
              {contractDetails.details.createAt &&
                format(
                  new Date(contractDetails.details.createAt),
                  'dd/MM/yyyy',
                )}
            </Text>
          </Flex>

          {!contractDetails?.details.isOneYearContract &&
            contractDetails?.details?.baseDate && (
              <Flex flexDirection="column" margin="5px" minWidth="200px">
                <Text size="small" color={Tokens.colors.gray400}>
                  Data base
                </Text>
                <Text
                  size="medium"
                  style={{
                    fontWeight: '400',
                  }}
                >
                  {format(
                    new Date(contractDetails.details.baseDate),
                    'dd/MM/yyyy',
                  )}
                </Text>
              </Flex>
            )}
        </Flex>

        {shouldShowJustification && (
          <Flex flexDirection="column" margin="5px" minWidth="200px">
            <Text size="small" color={Tokens.colors.gray400}>
              Justificativa
            </Text>
            <Text
              size="medium"
              style={{
                fontWeight: '400',
              }}
            >
              {contractDetails.details.closingJustification}
            </Text>
          </Flex>
        )}
      </SpacingTop>

      {contractDetails.details.status === 1 &&
        contractDetails.details.railwayItems.length > 0 && (
          <Flex flexDirection="column" margin="5px">
            <Text size="small" color={Tokens.colors.gray400}>
              Observação
            </Text>
            <Text size="large">
              Não houve cálculo do diesel para a data de aplicação de{' '}
              {getDateApplication(contractDetails.details.railwayItems)}, pois o
              índice inflacionário não foi atualizado. Aguarde a atualização
              pela inflação
            </Text>
          </Flex>
        )}

      <ExtendModal
        isOpen={isExtendModalOpen}
        onClose={handleCloseExtendModal}
      />

      <ServiceModal
        isOpen={isServiceModalOpen}
        onClose={handleCloseServiceModal}
      />
    </Card>
  );
};

CardDetail.propTypes = {};

CardDetail.defaultProps = {};

export default CardDetail;
